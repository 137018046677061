import * as React from "react";
import { InlineMath } from "react-katex";
import { graphql } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";
import wrapWithPostTemplate from "../../src/templates/post";
import Video from "../../src/components/video";
import Header from "../../src/images/header/pi.inline.svg";

export const frontmatter = {
  title: "Easy as π",
  subtitle:
    "Designing a mobile app to teach simple arithmetic to students from Grade 1 to 5.",
  meta: "React Native/Express.js • Posted December 1, 2019",
  author: "hello@golsteyn.com",
  category: "projects",
  date: "2019-12-01T00:00:00.000Z",
};

const EasyAsPi = () => (
  <>
    <p>
      They say the best way to learn math is by practicing problems over and
      over again. <b>What if we could generate these problems on-demand?</b>{" "}
      This is the premise of this project: to provide students with an endless
      supply of math problems.
    </p>
    <p>With Easy as π, we wanted to create a mobile application that could:</p>
    <ol>
      <li>Generates arithmetic problems at various levels of difficulty</li>
      <li>
        Selects the correct level of difficulty of problem to display to a
        student
      </li>
    </ol>
    <p>
      We completed this project in a team of 4 students as part of a software
      engineering course at the University of British Columbia. While the focus
      of the course was on the engineering aspect of software development
      (software architecture, scope, testing, etc.), we still took the
      opportunity to conduct user research prior to beginning development of
      this mobile app.
    </p>
    <h2>Understanding our potential users</h2>
    <p>
      This app has two primary groups of users, students and teachers, which
      have different set of needs. Given these groups, we created{" "}
      <b>several personas</b> for each group based on our initial online
      research and prior experience.
    </p>
    <p>
      <blockquote>
        <small>
          <b>Ewen</b> is a grade 3 student and loves video games. He can quickly
          get addicted to games on his parent's phones.{" "}
          <b>He is not very interested in school</b> and lags behing compared to
          his classmates.
        </small>
      </blockquote>
      <blockquote>
        <small>
          <b>Jess</b> is a teacher in a class of 32 students.{" "}
          <b>
            She struggles to find time to help all her students individually
          </b>{" "}
          and she is worried that if she spent more time, the rest of her class
          would not do anything productive.
        </small>
      </blockquote>
      <blockquote>
        <small>
          <b>Pete</b> is a consultant in an engineering firm and has difficulty
          coming home early most days of the week. He wants to make sure his son
          gets enough practice in mathematics to make sure he does well in
          school.
          <b>He wants him to learn how to study on his own</b>, but he doesn't
          know where to start and is often not around to help him.
        </small>
      </blockquote>
    </p>
    <p>
      From these personas, we brainstormed a list of potential needs and pain
      points for each group, which helped inform the design of the mobile app.
    </p>
    <figure>
      <StaticImage src="../image/pi/easyaspi-pains.png" />
      <figcaption>Selected pain points for both user groups</figcaption>
    </figure>
    <p>
      Based the on the user research above, we determined the following features
      our app should include:
    </p>
    <p>
      <b>Students</b>
    </p>
    <ol>
      <li>Work on a daily math set</li>
      <li>Ask for help from their teacher</li>
      <li>Access learning material to solve a particular problem</li>
      <li>Track their success rate</li>
    </ol>
    <p>
      <b>Teachers</b>
    </p>
    <ol>
      <li>Create a virtual classroom</li>
      <li>Prepare the content of the daily math set</li>
      <li>View individual student progress in each domain area</li>
      <li>Add recommended learning material</li>
    </ol>
    <h2>High-fidelity mockups</h2>
    <p>
      I was responsible for implementing the mobile app. I started by sketching
      a potential user flow for the authentication process and for the main
      features of the student app.
    </p>
    <figure className="full">
      <StaticImage src="../image/pi/easyaspi-auth.png" />
      <figcaption>App authentication user flow</figcaption>
    </figure>
    <p>
      With these sketches in mind, I created a set of high-fidelity mockups in
      Figma that outlines various steps of the onboarding process for teachers
      and students, and the primary screens of the student app.
    </p>
    <figure className="full">
      <StaticImage src="../image/pi/easyaspi-screenshots.png" />
    </figure>
    <p>
      Given more time, we would have started with low-fidelity mockups to
      present to teachers and parents before proceeding with more time-intensive
      prototypes. Further iterations before starting development could have
      helped us get a better understanding of what needed to be implemented, and
      avoid some churn later down the road.
    </p>
    <h2>Implementation</h2>
    <p>
      We developed the mobile application in React Native, using the Expo
      library. The backend was an Node.js Express server using MongoDB for data
      storage. Authentication was done with Google OAuth.
    </p>
    <h3>Generating math problems</h3>
    <p>
      The BC math curriculum from grade 1 to 5 was essential for this project.
      It provides specific learning outcomes students should be able to achieve
      at each year level. For example, in Grade 1, students are expected to be
      able to add numbers up to 10.
    </p>
    <p>
      To determine the correct problem to show the student, students are
      categorized according to their grade and their expertise within their
      grade. The rules selected to generate a problem of a particular type will
      vary depending on the student's assigned level. Each rule of a particular
      problem type can be formulated as a set of <b>controlled</b> and{" "}
      <b>derived</b> variables.
    </p>
    <p>
      For example, for addition problems in Grade 1, students are expected to be
      able to add a "large" number with a "small" number to values up to 9. In
      this scenario, we can define two controlled variables,{" "}
      <InlineMath>a</InlineMath> and <InlineMath>b</InlineMath> and one derived
      variable, <InlineMath>c</InlineMath>.
    </p>
    <p>
      Controlled variables have their values bounded, for this example{" "}
      <InlineMath>{"5 < c < 9"}</InlineMath> and{" "}
      <InlineMath>{"0 < a < 3"}</InlineMath>. A random value within the
      appropriate domain is assigned to each controlled variable when generating
      a math problem. The derived variables have a value derived from the
      controlled variables, here <InlineMath>b = c - a</InlineMath>.
    </p>
    <p>
      With the variables defined, we can then specify a problem template and
      insert the variable values in the problem statement and answer. For our
      addition example, the problem statement could be{" "}
      <i>
        Solve <InlineMath>a + b =\ ?</InlineMath>
      </i>{" "}
      and the answer would be <InlineMath>c</InlineMath>. With{" "}
      <InlineMath>c = 7</InlineMath> and <InlineMath>a = 2</InlineMath>, the
      generated problem would therefore be{" "}
      <i>
        Solve <InlineMath>2 + 5 =\ ?</InlineMath>
      </i>{" "}
      and the answer would be <InlineMath>7</InlineMath>.
    </p>
    <h3>Adapting to the student's performance</h3>
    <p>
      Depending on the student performance, we can adjust the category the
      student is in to vary the problems difficulty. Students score or lose
      points depending on whether they get problems right or wrong. Each level a
      student is in allows for a maximum of 10 points. If a students exceeds
      these 10 points, they "graduate" to the next category. If a students drops
      below zero point, they are demoted a level.
    </p>
    <p>
      Students receive one point for each correct answer, and lose 2 points for
      each wrong answer. It is important to note, however, that these scores are
      given per problem type. A student may be "Grade 2 high" on addition, but
      "Grade 1 medium" on substraction.
    </p>
    <h2>Final product</h2>
    <figure className="raise">
      <div style={{ maxWidth: 300, margin: "0 auto" }}>
        <Video
          src="https://player.vimeo.com/video/416581132?autoplay=1"
          image={<StaticImage src="../image/pi/easyaspi-app.png" />}
          ratio={1138 / 640}
        />
      </div>
    </figure>{" "}
    After 2 months of work, we succesfully implemented Easy as π! Ultimately,
    this project gave us the opportunity to develop a mobile application from
    scratch, something many members on our team had never done before.
  </>
);

export const query = graphql`
  query ($id: String) {
    javascriptFrontmatter(id: { eq: $id }) {
      frontmatter {
        author {
          email
          firstName
          name
        }
        category {
          name
        }
        meta
        subtitle
        title
        date
      }
    }
  }
`;

export default wrapWithPostTemplate(
  EasyAsPi,
  <Header alt="" className="hero_image" style={{ maxHeight: 150 }} />
);
